<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        :clearable="clearable"
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      :first-day-of-week="1"
      @input="show = false"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DateSelector',

  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    show: false
  }),

  computed: {
    dateFormatted: {
      get () {
        return this.formatDate(this.value)
      },
      set (val) {
        this.$emit('input', this.parseDate(val))
      }
    },
    date: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>

<style scoped>

</style>
